import React, { useState } from "react"

import AuthContext from "contexts/sessionContext"


const useSession = () => {
  const { user, logout } = React.useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false)

  const login = () => {
    setIsLoading(true)
    window.location.href = `https://idpdecathlon.oxylane.com/as/authorization.oauth2?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&state=${process.env.REACT_APP_URL}&redirect_uri=${process.env.REACT_APP_API_URL}/v1/auth/sso&response_type=code&scope=openid%20profile`
  }

  return { user, login, logout, isLoading }
}

export default useSession
