import React, { useEffect, useState, useContext } from "react"
import sessionContext from "../../contexts/sessionContext"
import { useQuery, useQueryClient } from "react-query"
import { ExclamationIcon, SearchIcon, InformationCircleIcon } from "@heroicons/react/outline"
import axios from "utils/axios"
import Pagination from "components/Pagination/Pagination"
import ProvidersList from "./components/ProvidersList"
import Provider from "types/Provider"
import Spinner from "components/Spinner/Spinner"
import queryKeys from "config/queries"


interface ProvidersApiResponse {
  page: Number,
  providers: Array<Provider>
  numberOfItems: number
}


const PAGINATION_STEP = 20

const Providers = () => {

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  const { data, isLoading, isFetching } = useQuery([queryKeys.PROVIDERS, search, page, PAGINATION_STEP], () => axios.get<ProvidersApiResponse>(`/providers`, {
    params: {
      page,
      search,
      limit: PAGINATION_STEP
    }
  }).then(res => res.data))

  const queryClient = useQueryClient()

  const [searchTerm, setSearchTerm] = useState("")



  // Seach delay to reduce API calls
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm)
      setPage(1)
    }, 250)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <div className="flex flex-col overflow-hidden p-4 space-y-4">
      {/* Alert about privacy of data */}
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="text-sm text-blue-700">
              <p><span className="font-medium">A tener en cuenta:</span> En caso de no encontrar tu proveedor en el buscador, él te dará la opción <span className="font-medium">¨AQUI¨</span> dondé te llevara a un forms el cual debes diligenciarlo según lo indicado.</p>
              <p className="mt-2" >El directorio se actualizara semana a semana, los reembolsos deben ser cargados con el tercero solo si cuentas con una "factura electronica o en su defecto que tenga CUFE y código QR a nombre de Decathlon o Decoam" en caso de contar con otro soporte no dudes en escribirle a tu Accounting. Adicional queremos recomendarles no cambiar el correo de contabilidad en los establecimientos, ya que necesitamos aprobarlas y validarlas en la DIAN y así mismo no perder la información, el correo de contabilidad es el siguiente <span className="font-medium">contabilidad.colombia@decathlon.com </span>.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Alert about privacy of data */}
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              La información de los proveedores es confidencial y no se deben compartir con externos.
            </p>
          </div>
        </div>
      </div>
      <div className="relative rounded-md shadow">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          type="text"
          name="search"
          id="search"
          className="block w-full pr-10 pl-10 text-lg rounded-md border-gray-300"
          placeholder="Buscar un proveedor"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {
            search && isLoading && <Spinner size="small" />
          }
        </div>
      </div>
      <div className="flex flex-col mt-8 shadow-lg sm:rounded-lg -mx-6 sm:mx-0">

        <ProvidersList providers={data?.providers} />

        {data && <Pagination page={page} onChange={setPage} total={data.numberOfItems} step={PAGINATION_STEP} />}
      </div>
    </div >
  )

}

export default Providers
