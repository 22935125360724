import { useMutation } from "react-query"


import axios from "utils/axios"


const useUploadExcel = (apiPath: string, onUploaded?: Function) => {

  const { mutate, isLoading, error, isSuccess, isError, reset } = useMutation((data: FormData) => axios.post(apiPath, data, {
    headers: {
      "content-Type": "multipart/form-data"
    }
  }), {
    onSuccess: () => onUploaded && onUploaded()

  })

  const uploadExcelFile = async (file: File) => {
    if (!file) return
    const formData = new FormData()
    formData.append("file", file)
    mutate(formData)
  }
  return { uploadExcelFile, isLoading, error, isSuccess, isError, reset }

}
export default useUploadExcel
