import { LogoutIcon, PlusIcon, UserGroupIcon, UserAddIcon } from "@heroicons/react/outline";
import NewProviderModal from "components/NewProviderModal/NewProviderModal";
import sessionContext from "contexts/sessionContext";
import Login from "pages/Login/Login";
import UploadProvidersModal from "components/UploadProvidersModal/UploadProvidersModal";
import UserAccessModal from "components/UserAccessModal/UserAccessModal";
import Providers from "pages/Providers/Providers";
import { useState } from "react";
import useSession from "hooks/useSession";

function App() {

  const [showNewProviderModal, setNewProviderModal] = useState(false)
  const [uploadProvidersModal, setUploadProvidersModal] = useState(false)
  const [userAccessModal, setUserAccessModal] = useState(false)

  const { user, logout } = useSession()

  if (!user) return <Login />


  return (
    <div className="flex flex-col justify-center h-screen relative w-screen overflow-hidden">
      <div className="sticky left-0 right-0 top-0 p-4 bg-gray-800 z-10 flex items-center justify-between">
        <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Proveedores Decathlon</h2>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <div className="text-white flex items-center">
            <p>{user.id}</p>
            <button className="mx-2 focus:outline-none" onClick={logout}><LogoutIcon className="h-5" /></button>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto w-full h-full bg-gray-50">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-14 pt-6 ">
          <Providers />
        </div>
      </div>
      {
        user.role &&
        <div className="absolute bottom-8 right-4 md:bottom-10 md:right-10 flex flex-col space-y-2 ">
          <button
            onClick={() => setUploadProvidersModal(true)}
            aria-label="Crear un proveedor"
            className=" bg-blue-500 p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-2">
            <UserGroupIcon className="h-8 text-white" />
          </button>
          <button
            onClick={() => setNewProviderModal(true)}
            aria-label="Crear un proveedor"
            className=" bg-blue-500 p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-2">
            <PlusIcon className="h-8 text-white" />
          </button>
          <button
            onClick={() => setUserAccessModal(true)}
            aria-label="Crear un proveedor"
            className=" bg-blue-500 p-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ring-offset-2">
            <UserAddIcon className="h-8 text-white" />
          </button>
        </div>
      }
      <UserAccessModal visible={userAccessModal} onClose={() => setUserAccessModal(false)} />
      <UploadProvidersModal visible={uploadProvidersModal} onClose={() => setUploadProvidersModal(false)} />
      <NewProviderModal visible={showNewProviderModal} onClose={() => setNewProviderModal(false)} />
    </div>
  )
}

export default App;
