import clsx from "clsx"

interface PaginationProps {
  page: number
  onChange: (page: number) => void
  total: number
  step: number
}

const Pagination = ({ page, onChange, total, step }: PaginationProps) => {

  const nextButtonDisabled = (page) >= total / step
  const previousButtonDisabled = page == 1

  const buttonClassnames = "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"

  const previousButtonClassnames = clsx(
    buttonClassnames,
    previousButtonDisabled ? "bg-gray-100 text-gray-500" : "bg-white hover:bg-gray-50 text-gray-700"
  )

  const nextButtonClassnames = clsx(
    buttonClassnames,
    "ml-3",
    nextButtonDisabled ? "bg-gray-100 text-gray-500" : "bg-white hover:bg-gray-50 text-gray-700"
  )

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Mostrando del <span className="font-medium">{(page - 1) * step + 1}</span> al <span className="font-medium">{Math.min((page - 1) * step + step, total)}</span> de{' '}
          <span className="font-medium">{total}</span> proveedores
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          onClick={() => onChange(page - 1)}
          disabled={previousButtonDisabled}
          className={previousButtonClassnames}
        >
          Anterior
                </button >
        <button
          onClick={() => onChange(page + 1)}
          disabled={nextButtonDisabled}
          className={nextButtonClassnames}
        >
          Siguiente
        </button>
      </div>
    </nav >
  )

}

export default Pagination