import { EmojiSadIcon } from "@heroicons/react/outline"
import Spinner from "components/Spinner/Spinner"
import Provider from "types/Provider"
import ProviderListItem from "./ProviderListItem"

const columns = [
  "Codigo",
  "Razon social",
  "NIT",
  "Dirección",
  "Ciudad",
]


interface ProvidersListProps {
  providers: Array<Provider> | undefined
}

const ProvidersList = ({ providers }: ProvidersListProps) => {

  return (
    <div className="overflow-x-auto ">
      <div className="align-middle inline-block min-w-full">
        <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg "></div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {
                columns.map(column => (
                  <th
                    key={column}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {column}
                  </th>
                ))
              }
            </tr>
          </thead>

          <tbody className="bg-white">
            {
              !providers && <tr>
                <td colSpan={7} >
                  <div className="flex justify-center w-full text-blue-500 py-4">
                    <Spinner size="medium" />
                  </div>
                </td>
              </tr>
            }
            {
              providers && providers.length === 0 && <tr>
                <td colSpan={7}>
                  <p className="text-gray-500 p-4 flex items-center text-sm"><EmojiSadIcon className="mr-2 h-4" />No hay proveedor para este filtro. Ingresa <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEykevlEmZIdCDeuKDicA1O__-gKP9WFK8heZZsPhO_kmZLA/viewform?gxids=7628" className="px-1 underline font-bold" target="_blank"> aqui</a> para solicitar la creación de uno nuevo.</p>

                </td>
              </tr>
            }
            {
              providers && providers.length > 0 && providers.map((provider, providerIndex) => <ProviderListItem key={provider.code} provider={provider} index={providerIndex} />)
            }
          </tbody>
        </table >
      </div>
    </div>
  )
}

export default ProvidersList
