import { EffectCallback, Fragment, useEffect, useState } from 'react'
import { appendErrors, useForm } from 'react-hook-form'
import { useQueryClient, useMutation } from 'react-query'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import queryKeys from "config/queries"
import Input from "components/Input/Input"
import axios from 'utils/axios'
import Provider from 'types/Provider'
import Button from '../Button/Button'

interface NewProviderModalProps {
  visible: boolean
  onClose: () => void
}

const NewProviderModal = ({ visible, onClose }: NewProviderModalProps) => {

  const { register, handleSubmit, formState: { errors }, reset: resetForm } = useForm({
    reValidateMode: "onBlur",

  })
  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, reset } = useMutation((newProvider: Provider) => axios.post('providers/', newProvider), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.PROVIDERS)
      handleClose()

    }
  })

  const createNewProvider = (newProvider: Provider) => mutate(newProvider)


  const onSubmit = async (data: any) => {

    createNewProvider({ ...data })

  }
  const handleClose = () => {
    onClose()
    resetForm()
    reset()

  }

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={visible} onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-8 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block  align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-8">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 pt-4 pb-6">
                    Nuevo proveedor
                  </Dialog.Title>
                  <form className="grid gap-6" onSubmit={handleSubmit(onSubmit)}>
                    <Input label="Código" placeholder="e.g 16957" {...register("code", { required: true })} error={errors.code} type="number" />

                    <Input label="Razón Social" placeholder="e.g Decathlon Colombia S.A.S" {...register("legalName", { required: true })} error={errors.code} />

                    <Input label="Nit" placeholder="e.g 9008682711" {...register("nit", { required: true })} error={errors.code} />

                    <Input label="Dirección" placeholder="e.g Calle 134 #9-51" {...register("address", { required: true })} error={errors.code} />

                    <Input label="Ciudad" placeholder="e.g Bogotá" {...register("city", { required: true })} error={errors.code} />
                    {isError &&
                      <div className="bg-red-50 border-l-4 border-red-400 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-red-700">
                              Ya existe un registro con este código
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="flex flex-row justify-end space-x-2 mt-3 mb-0">
                      <Button variant="secondary"
                        disabled={isLoading}
                        onClick={handleClose}
                      > Cancelar</Button>
                      <Button isLoading={isLoading} type="submit">Crear Proveedor</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root >
  )
}

export default NewProviderModal
