import { useQueryClient, useMutation } from "react-query"
import { useForm } from "react-hook-form"
import User from "types/User"
import axios from "utils/axios"
import queryKeys from "config/queries"
import Modal from "components/Modal/Modal"
import Input from "components/Input/Input"
import Button from "components/Button/Button"
import { ExclamationCircleIcon } from "@heroicons/react/outline"


interface UserAccessModalProps {
  onClose: () => void
  visible: boolean
}

const UserAccessModal = ({ visible, onClose }: UserAccessModalProps) => {

  const { register, handleSubmit, formState: { errors }, reset: resetForm } = useForm({
    reValidateMode: "onBlur"
  })

  const queryClient = useQueryClient()

  const { mutate, isLoading, isError, reset } = useMutation((newUserAccess: User) => axios.post('users/', newUserAccess), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.USER_ACCESS)
      handleClose()
    }
  })

  const createUserAccess = (newUserAccess: User) => mutate(newUserAccess)

  const onSubmit = async (data: any) => {
    createUserAccess({ ...data, role: "admin" })
  }

  const handleClose = () => {
    onClose()
    resetForm()
    reset()
  }

  return (
    <Modal visible={visible} onClose={handleClose}>
      <div className="mx-4">
        <div className="flex flex-col space-y-4 w-full">
          <h1 className="font-medium">Configurar acceso de administrador</h1>
        </div>
        <div className="w-full border-t py-2 border-gray-300">
          <form className="flex flex-col space-y-4 mt-4" onSubmit={handleSubmit(onSubmit)}>
            {/* <Input
              label="Email Decathlon"
              placeholder="ivan.gonzalez@decathlon.com"
              {...register("email", { required: true })}
              error={errors.email}

            /> */}
            <Input
              label="ID Decathlon"
              placeholder="IGONZA58"
              {...register("id", { required: true })}
              error={errors.id}
            />
            {isError &&
              <div className="bg-red-50 border-l-4 border-red-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      Debes ingresar un correo electrónico válido
                    </p>
                  </div>
                </div>
              </div>
            }
            <div className="flex flex-row justify-end space-x-2 pt-4">
              <Button variant="secondary"
                disabled={isLoading}
                onClick={handleClose}
              > Cancelar</Button>
              <Button isLoading={isLoading} type="submit">Crear Acceso</Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )

}

export default UserAccessModal
