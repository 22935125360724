import Spinner from "components/Spinner/Spinner"
import useSession from "hooks/useSession"



const Login = () => {

  const { login, isLoading } = useSession()

  return (
    <main className="md:h-screen lg:relative overflow-auto md:overflow-hidden">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">Directorio de proveedores</span>{' '}
            <span className="block text-blue-600 xl:inline">Decathlon Colombia</span>
          </h1>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <button
                disabled={isLoading}
                onClick={login}
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >

                {
                  isLoading && <Spinner size="small" />
                }
                                Conectate con Decathlon
                            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1568632234180-0e6c08735d01?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
          alt=""
        />
      </div>
    </main>
  )

}

export default Login
