import a from "axios"

const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN_STORAGE_KEY}`)

const axios = a.create({
  baseURL: process.env.REACT_APP_API_URL + "/v1",
  headers: {
    Authorization: `Bearer ${token}`
  }
})

export default axios
