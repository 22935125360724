import Provider from "types/Provider"

interface ProviderListItemProps {
  provider: Provider
  index: number
}

const ProviderListItem = ({ provider, index }: ProviderListItemProps) => {

  return (
    <tr key={provider.code} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{provider.code}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{provider.legalName}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{provider.nit}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{provider.address}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{provider.city}</td>


    </tr>
  )

}

export default ProviderListItem
