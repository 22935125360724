import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import jwt from "jsonwebtoken"

import User from "types/User";
import axios from "utils/axios";
import Spinner from "components/Spinner/Spinner";

export const TOKEN_STORAGE_KEY = `${process.env.REACT_APP_TOKEN_STORAGE_KEY}`;


interface SessionContext {
  user: User | undefined | null;
  logout: () => void
}

const sessionContext = React.createContext<SessionContext>({
  user: undefined,
  logout: () => false
})
export default sessionContext

interface SessionContextProviderProps {
  children: React.ReactNode
}

export const SessionContextProvider = ({ children }: SessionContextProviderProps) => {

  const queryClient = useQueryClient()

  useEffect(() => {
    if (user) return
    const query = new URLSearchParams(window.location.search)
    const token = query.get("token")
    if (!token) return
    localStorage.setItem(TOKEN_STORAGE_KEY, token)
    window.location.search = ""
    authenticate()
  }, [])


  const verifyToken = async () => await axios.get<User | undefined>(`/auth/validate-token`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}`
    }
  }).then(res => res.data)

  const { data: user, isLoading, refetch: authenticate } = useQuery(
    TOKEN_STORAGE_KEY,
    verifyToken,
    {
      enabled: !!localStorage.getItem(TOKEN_STORAGE_KEY),
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: () => {
        axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}`
      },
      onError: (err) => {
        localStorage.removeItem(TOKEN_STORAGE_KEY)
      }
    })

  const logout = () => {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    queryClient.setQueryData(TOKEN_STORAGE_KEY, () => null)
  }

  if (isLoading) return <div className="bg-gray-800 h-screen w-screen flex flex-col items-center justify-center">
    <Spinner size="large" />
    <p className="text-white mt-6 text-xl font-medium">Verificando acceso...</p>
  </div>

  const context = { user, logout }

  return (
    <sessionContext.Provider value={context} >
      {children}
    </sessionContext.Provider >
  )
}


