import { DownloadIcon, InformationCircleIcon, UploadIcon, ExclamationCircleIcon } from "@heroicons/react/outline"
import Button from "../Button/Button"
import Modal from "../Modal/Modal"
import queryKeys from "config/queries"
import useUploadExcel from "../../hooks/useUploadExcel"
import { useForm } from "react-hook-form"
import { useQueryClient } from "react-query"




interface UploadProvidersModalProps {
  onClose: () => void
  visible: boolean
}

const UploadProvidersModal = ({ visible, onClose }: UploadProvidersModalProps) => {

  const { register, handleSubmit, reset: resetForm } = useForm()

  const queryClient = useQueryClient()

  const { uploadExcelFile, isLoading, isSuccess, isError, reset } = useUploadExcel("/providers/massive", () => {
    queryClient.invalidateQueries(queryKeys.PROVIDERS)
    handleClose()
  })


  const onSubmit = (data: any) => uploadExcelFile(data.files[0])

  const handleDownloadTemplate = () => {
    window.open(`${process.env.REACT_APP_API_URL}/templates/providers_template.xlsx`, "_blank")
  }
  const handleClose = () => {
    onClose()
    resetForm()
    reset()
  }


  return (
    <Modal visible={visible} onClose={handleClose}>
      <div className="flex flex-col space-y-4 w-full">
        <h1 className="font-medium">Creación masiva de proveedores</h1>
        <div className="rounded-md bg-blue-50 p-4 w-full">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 text-left text-sm text-blue-700">
              <p>Descarga el template excel y diligéncialo con la información requerida de los proveedores.</p>
            </div>
          </div>
          <div></div>
        </div>
        <div className="flex justify-center w-full">
          <Button onClick={handleDownloadTemplate}><DownloadIcon className="h-5 mr-2" />Descargar el template</Button>
        </div>
        <div className="w-full border-t border-gray-300" />
        <form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md shadow-sm">
            <svg className="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <input
              type="file"
              {...register("files", { required: true })}
            />

          </div>
          {isError &&
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    Ya existe un registro con este código
                  </p>
                </div>
              </div>
            </div>
          }
          <div className="flex justify-end w-full space-x-2 py-2 ">
            <Button variant="secondary"
              disabled={isLoading}
              onClick={handleClose}
            >Cancelar</Button>
            <Button
              isLoading={isLoading}
              type="submit"><UploadIcon className="h-5 mr-2" />Subir y crear proveedores</Button>
          </div>
        </form>
      </div>

    </Modal>
  )
}

export default UploadProvidersModal
